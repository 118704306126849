import { ref } from 'vue'
import { useUserStore } from '@/store/modules/user'
const SOCKET_URL = import.meta.env.VITE_SOCKET_URL
import { ElNotification } from 'element-plus'
const NEW_ORDER_VOICE = new URL('@/assets/mp3/new_order.mp3', import.meta.url).href
const CANCEL_ORDER_VOICE = new URL('@/assets/mp3/cancel_order.mp3', import.meta.url).href
const NEW_APPROVAL_VOICE = new URL('@/assets/mp3/new_approval.mp3', import.meta.url).href
const alarmAudio = new Audio();
var NOTIFY_BOX = null
const WebSocketPlugin = {
    install(app) {
        let ws = ref(null)
        let message = ref(null)
        let callback = ref(null)
        const wsUrl = ref(null)//连接的socketurl
        //避免ws重复连接
        const lockReconnect = ref(false)
        //手动关闭
        const ownClose = ref(false)
        const connect = () => {
            const userInfo = useUserStore().getUserInfo
            if (!userInfo || !userInfo.id || userInfo.userType != 2) return //用户类型为管理员才开启
            const random = Math.floor(Math.random() * 100000)//5位随机数,实现同账户不同电脑登录能收到消息
            wsUrl.value = SOCKET_URL + '/' + userInfo.id + '-' + random
            try {
                if ('WebSocket' in window) {
                    ws.value = new WebSocket(wsUrl.value)
                    ownClose.value = false;
                    ws.value.onopen = () => {
                        console.log('socket 打开')
                        //心跳检测重置
                        heartCheck.reset().start()
                        // ws.value.send(
                        //     JSON.stringify({
                        //         token: localStorage.getItem('token')
                        //     })
                        // )
                        // sendAndMessage()
                    }
                    ws.value.onmessage = event => {
                        console.log('socket 接收消息:' + event.data)
                        //拿到任何消息都说明当前连接是正常的
                        heartCheck.reset().start()
                        if (event.data == 'connect_success') return; //连接成功后的回复,不处理
                        const msgObj = JSON.parse(event.data)
                        doNotification(msgObj)
                    }

                    ws.value.onclose = event => {
                        console.log('socket 关闭:' + JSON.stringify(event))
                        ws.value === null
                        if (!ownClose.value) {
                            reconnect()
                        }
                    }
                    ws.value.onerror = () => {
                        console.log('socket 错误')
                        ws.value = null
                        reconnect()
                    }
                }
            } catch (e) {
                console.log('socket  catch 异常');
                reconnect()
            }
        }

        const reconnect = () => {
            if (lockReconnect.value) {
                return
            }
            lockReconnect.value = true
            setTimeout(function () {
                console.log('重连');
                connect()
                lockReconnect.value = false
            }, 2000)
        }
        // 关闭
        const close = () => {
            if (ws.value && ws.value.readyState !== WebSocket.CLOSED) {
                ownClose.value = true
                ws.value.close(1000)
                ws.value = null
                message.value = null
                callback.value = null
            }
        }

        //发送消息初始化
        const sendAndMessage = () => {
            if (ws.value?.readyState === WebSocket.OPEN) {
                ws.value.send()
            } else if (ws.value?.readyState !== WebSocket.CONNECTING) {
                reconnect()
            }
        }
        //页面初始化方法
        const init = (msg, cb) => {
            message.value = msg
            callback.value = cb
            sendAndMessage()
        }

        //心跳检测
        const heartCheck = {
            //这里设置10分钟发一次心跳
            timeout: 10 * 60 * 1000,
            timeoutObj: null,
            reset: function () {
                clearTimeout(this.timeoutObj)
                return this
            },
            start: function () {
                this.timeoutObj && clearTimeout(this.timeoutObj)
                this.timeoutObj = setTimeout(function () {
                    //检测状态           
                    sendAndMessage()
                }, this.timeout)
            }
        }

        app.provide('wsInit', init)
        app.provide('wsClose', close)
        app.provide('wsConnect', connect)
    }
}

//播放通知
const doNotification = (msgObj) => {
    let title = '';
    let msg = '订单编号：' + msgObj.data.orderNo + "，时间：" + msgObj.data.time
    let voice = ''
    if (msgObj.code == 2000) {
        //新订单通知
        title = '您有一条新订单,请及时处理'
        msg += '，请及时前往订单中心处理。'
        voice = NEW_ORDER_VOICE
    } else if (msgObj.code == 2001) {
        //退款申请(关闭订单)
        title = '您有一条新的退款申请，请及时处理'
        msg += '，请及时前往订单中心-退票申请中处理。'
        voice = CANCEL_ORDER_VOICE
    } else if (msgObj.code == 2002) {
        //抢票超时订单
        title = '您有一条新的抢票超时,请及时处理'
        msg += '，请及时前往订单中心-退票申请中处理。'
        voice = NEW_APPROVAL_VOICE
    } else return;
    ElNotification({
        title: title,
        message: msg,
        duration: 8000,//6s自动关闭
        type: 'warning',
    })
    playAudio(voice)
}

const playAudio = (voice) => {
    alarmAudio.pause()//暂停正在播放的提示音,避免重复
    alarmAudio.src = voice
    alarmAudio.load()
    alarmAudio.play()
        .then(() => {
            NOTIFY_BOX && NOTIFY_BOX.close();
            NOTIFY_BOX = null
        })
        .catch((error) => {
            console.log('播放失败', error);
            if (error.name === "NotAllowedError") {
                if (NOTIFY_BOX) return;
                NOTIFY_BOX = ElNotification({
                    title: "",
                    duration: 0,
                    showClose: false,
                    position: 'bottom-right',
                    dangerouslyUseHTMLString: true,
                    onClick: () => {
                        alarmAudio.pause()
                        NOTIFY_BOX && NOTIFY_BOX.close();
                        NOTIFY_BOX = null
                    },
                    message:
                        "<div style='color:#000;font-size:18px;cursor:pointer'>因浏览器限制，需<span style='color:#ff2c55'>点击打开声音</span></div>",
                });
            }
        });
}
export default WebSocketPlugin